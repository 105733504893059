import React from "react";
import { Row, Col, CardBody, Card, Alert,Container } from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
import { navigate, Link } from "gatsby";
import SEO from "../components/seo";

  export default function Register(props) {
     const handleSubmit = ()=>{
          navigate("/dashboard")
      }
    return (
      <React.Fragment>
        <SEO/>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2"></i>
          </Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
              <div className="d-flex justify-content-center">
                      <Link to="/">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src="/images/paraffin-logo.png"
                              alt=""
                              className="rounded-circle"
                              height="54"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                <Card className="overflow-hidden">
                  <div>
                    <Row>
                      <Col className="col-8">
                        <div className="text-primary p-2">
                          <h5 className="text-primary">Free Register</h5>
                          <p>Get your free ParaffinIoT account now.</p>
                        </div>
                      </Col>
                      {/* <Col className="col-4 align-self-end">
                        <img src="/images/profile-img.png" alt="" className="img-fluid" />
                      </Col> */}
                    </Row>
                  </div>
                  <CardBody className="pt-2">
                    <div className="p-2">
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={handleSubmit}
                      >
                        {props.user && props.user ? (
                          <Alert color="success">
                            Register User Successfully
                          </Alert>
                        ) : null}
                        {props.registrationError &&
                          props.registrationError ? (
                            <Alert color="danger">
                              {props.registrationError}
                            </Alert>
                          ) : null}

                        <div className="form-group">
                          <AvField
                            name="email"
                            label="Email"
                            value="admin@paraffinIoT.com"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            required
                          />
                        </div>
                        <div className="form-group">
                          <AvField
                            name="password"
                            label="Password"
                            type="password"
                            value="123456"
                            required
                            placeholder="Enter Password"
                          />
                        </div>

                        <div className="mt-4">
                          <button
                            className="btn btn-primary btn-block waves-effect waves-light"
                            type="submit"
                          >
                            Register
                          </button>
                        </div>

                        <div className="mt-4 text-center">
                          <p className="mb-0">
                            By registering you agree to the ParaffinIoT{" "}
                            <Link to="#" className="text-primary">
                              Terms of Use
                            </Link>
                          </p>
                        </div>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    Already have an account ?{" "}
                    <Link
                      to="/login"
                      className="font-weight-medium text-primary"
                    >
                      {" "}
                      Login
                    </Link>{" "}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }

